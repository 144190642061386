import { wristTemperature } from 'assets/iconify/wrist-temperature';
import { DataCellProps } from 'models/population-overview.model';
import { DataStatus } from '../types/enum/data-status.enum';
import { WristTemperatureStatus } from '../types/enum/wrist-temperature-status.enum';

export const getWristTemperatureStatus = (
  status: WristTemperatureStatus | undefined,
  value?: number,
): DataCellProps & { value?: string | number } => {
  const roundedValue = value ? Math.round(value * 10) / 10 : undefined;

  if (status === WristTemperatureStatus.NORMAL) {
    return {
      icon: wristTemperature.icons.normal,
      status: DataStatus.POSITIVE,
      label: 'Normal',
      value: `${roundedValue}°F`,
    };
  }

  if (status === WristTemperatureStatus.HIGH) {
    return {
      icon: wristTemperature.icons.high,
      status: DataStatus.NEGATIVE,
      label: 'High',
      value: `${roundedValue}°F`,
    };
  }

  if (status === WristTemperatureStatus.LOW) {
    return {
      icon: wristTemperature.icons.low,
      status: DataStatus.NEGATIVE,
      label: 'Low',
      value: `${roundedValue}°F`,
    };
  }

  // const disabledText = (textToDisable: string) => {
  //   return (
  //     <span style={{color: "rgb(200, 208, 220)"}}>{textToDisable}</span>
  //   )
  // }

  if (status === WristTemperatureStatus.NO_DATA) {
    return {
      icon: wristTemperature.icons.noData,
      status: 'no data',
      label: 'No data',
      value: `°F`,
    };
  }

  return {
    icon: wristTemperature.icons.disabled,
    status: DataStatus.DISABLED,
    label: 'Disabled',
    value: `°F`,
  };
};
